import React from 'react';
import {BlockTextWrap, BlockTextInner, Content, BtnWrap} from './block-text.stc'

const BlockText = ({title, ctaLabel, ctaPath, padding}) => {
    return (
        <BlockTextWrap style={{padding: padding}}>
            <BlockTextInner>
                <Content>
                    {title && <h2>{title}</h2>}
                    {ctaPath && <BtnWrap href={ctaPath}>{ctaLabel}</BtnWrap>}
                </Content>
            </BlockTextInner>
        </BlockTextWrap>
    )
}

export default BlockText;