import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import SectionTitle from '../../../components/shared/section-title'
import Project from '../../../components/project'
import { ProjectSectionWrap } from './project.stc'
import {pathWithLanguage} from "../../../utils/utilFunctions";
import BlockText from "../../layout/block-text";

const ProjectSection = ({ section, project, lang }) => {
	const projectQueryData = useStaticQuery(graphql`
    query ProjectDataQuery {
		homedataJson(section: {eq: "project_section_content"}) {
			i18n {
				ar {
					title
					subtitle
				}
				en {
					title
					subtitle
				}
			}
		}
		allProjectsJson(filter: {is_featured: {eq: true}}, limit: 3) {
			edges {
				node {
					jsonId
					i18n {
						ar {
							title
							subtitle
							location
						}
						en {
							title
							subtitle
							location
						}
					}
					featured_image {
						childImageSharp {
							gatsbyImageData(width: 1060, height: 600, layout: CONSTRAINED, quality: 100, placeholder: TRACED_SVG)
						}
					}
				}
			}
		}
		translationJson {
			projects {
			  projectName { ar en }
			  sector { ar en }
			  location { ar en }
			  seeMore { ar en }
			}
		}
    }  
    `);
	const projectSecData = { ...projectQueryData.homedataJson, ...projectQueryData.homedataJson.i18n[lang] };
	const projectsData = projectQueryData.allProjectsJson.edges.map(edge => {
		return {node: {...edge.node, ...edge.node.i18n[lang] }};
	});
	const translations = projectQueryData.translationJson.projects;
	const titles = {};
    Object.keys(translations).forEach(key => {
		titles[key] = translations[key][lang];
	});
	return (
		<ProjectSectionWrap {...section}>
			<div className="project-container">
				<div className="col-1 offset-1">
					<SectionTitle
						title={projectSecData.title}
						subtitle={projectSecData.subtitle}
					/>
				</div>

				<div className="project-wrapper">
					{projectsData.map((data, i) => {
						let isEven = i % 2 === 0;
						return (
							<Project
								{...project}
								isEven={isEven}
								key={data.node.jsonId}
								id={data.node.jsonId}
								image={data.node.featured_image}
								title={data.node.title}
								location={data.node.location}
								subtitle={data.node.subtitle}
								lang={lang}
								titles={titles}
							/>
						)
					})}
				</div>

				<BlockText ctaLabel={titles.seeMore} ctaPath={pathWithLanguage(lang, `/project`)} padding='100px 0 0px' />
			</div>
		</ProjectSectionWrap>
	)
}

ProjectSection.propTypes = {
	section: PropTypes.object,
	project: PropTypes.object
}

ProjectSection.defaultProps = {
	section: {
		backgroundColor: '#f8f8f8'
	},
	project: {
		mt: '100px',
		responsive: {
			medium: {
				mt: '50px'
			}
		}
	}
}

export default ProjectSection
